<template>
  <layout header-pre-title="configurações" header-title="Qualificações">
    <template v-slot:page-action-button>
      <RouterLink class="btn btn-primary raise" to="/config/qualifications/create">Adicionar qualificação</RouterLink>
    </template>
    <template v-slot:content>
      <qualifications-list v-if="qualificationList"></qualifications-list>
    </template>
  </layout>
</template>

<script>
import Layout from "../../../components/layout/Layout";
import QualificationsList from "../../../components/configs/qualifications/QualificationsList";

export default {
  components: {QualificationsList, Layout},
  name: "Qualifications",

  created() {
    this.$store.dispatch('qualifications/fetchQualificationsList');
  },
  computed: {
    qualificationList() {
      return this.$store.getters['qualifications/getQualificationsList'];
    }
  }
}
</script>

<style scoped>

</style>
