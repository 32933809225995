<template>
  <pagination-table v-if="qualifications.data !== undefined && qualifications.data.length > 0" action="qualifications/fetchQualifications" :meta="qualifications.meta" :seekable="false">
    <template v-slot:content1>
      <div class="row" style="background-color: #f9fbfd;">
        <div class="col-1"></div>
        <div class="col col-header">NOME</div>
        <div class="col col-header">CPC</div>
        <div class="col col-header">CONVERSÃO</div>
        <div class="col col-header"></div>
      </div>
      <draggable v-model="qualificationsSort" tag="transition-group" style="width: 100%" item-key="id">
        <div class="row" v-for="(qualification, index) in qualifications.data"
             :key="qualification.id">
          <div class="col-1 col-body icon-drag"><i class="fe fe-align-justify"></i></div>
          <div class="col col-body">
            <span class="dot" :style="`background-color:${qualification.color}`"></span>
            {{ qualification.name }}
          </div>
          <div class="col col-body">
            <div class="form-check me-n3">
              <input v-model="qualifications.data[index].dmc"
                     class="form-check-input"
                     @change="updateQualifications(qualifications.data[index])"
                     type="checkbox" id="dmc1">
              <label class="form-check-label" for="dmc1"></label>
            </div>
          </div>
          <div class="col col-body">
            <div class="form-check me-n3">
              <input v-model="qualifications.data[index].conversion"
                     class="form-check-input"
                     @change="updateQualifications(qualifications.data[index])"
                     type="checkbox" id="conversation1">
              <label class="form-check-label" for="conversation1"></label>
            </div>
          </div>
          <div class="col col-body text-end">
            <!-- Dropdown -->
            <div class="dropdown">
              <a class="dropdown-ellipses dropdown-toggle" href="#" role="button"
                 data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fe fe-more-vertical"></i>
              </a>
              <div class="dropdown-menu dropdown-menu-end">
                <router-link class="dropdown-item cursor-pointer"
                             @click="setQualificationToUpdate(qualification)"
                             :to="`/config/qualifications/edit`">
                  Editar
                </router-link>
                <a class="dropdown-item cursor-pointer" @click="deleteQualification(qualification)">
                  Excluir
                </a>
              </div>
            </div>
          </div>
        </div>
      </draggable>
    </template>
  </pagination-table>

</template>

<script>
import PaginationTable from "../../common/PaginationTable";
import {VueDraggableNext} from 'vue-draggable-next'

export default {
  name: "QualificationsList",
  components: {
    draggable: VueDraggableNext,
    PaginationTable
  },

  computed: {
    qualificationsSort: {
      get() {
        return this.$store.getters['qualifications/getQualifications'].data;
      },
      set(value) {
        let ids = [];

        for (let i = 0; i < value.length; i++) {
          ids.push(value[i].id);
        }

        this.$store.dispatch('qualifications/setQualificationsSortable', {payload: value, ids: ids});
      }
    },

    qualifications() {
      return this.$store.getters['qualifications/getQualifications'];
    }
  },

  methods: {
    updateQualifications(qualification) {
      if (qualification.conversion) {
        qualification.dmc = true;
      }
      qualification.days_limit = 7

      this.$store.dispatch('qualifications/fetchUpdateQualification', {id: qualification.id, payload: qualification})
    },

    setQualificationToUpdate(qualification) {
      this.$store.dispatch('qualifications/setQualificationToUpdate', qualification)
    },

    deleteQualification(qualification) {
      this.$swal({
        title: 'Você realmente deseja excluir essa qualificação?',
        text: 'Você não pode reverter essa ação',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Não, cancelar!',
        showLoaderOnConfirm: true
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch('qualifications/fetchDeleteQualification', {id: qualification.id})
          this.$swal('Exclusão realizada!', 'Qualifcação excluída com sucesso!', 'success')
        } else {
          this.$swal('Exclusão cancelada', 'Qualifcação não excluída', 'info')
        }
      });
    }
  },

  mounted() {
    this.setQualificationToUpdate({});
    this.$store.dispatch('qualifications/fetchQualifications');
  }
}
</script>

<style scoped>
.dot {
  height: 10px;
  width: 10px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.col {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.col-1 {
  width: 4.33333333%;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.col-body {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.text-end {
  padding-right: 24px !important;
}

.col-header {
  padding: 16px !important;
  font-size: 10px;
  font-weight: bold;
  color: #c2b3c9;
}

.icon-drag {
  padding-left: 25px !important;
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.fe-align-justify {
  color: #c2b3c9;
}
</style>
